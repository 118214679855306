.border {
  border: 2px solid #8be9fd;
  border-radius: 8px;
}

.pad {
  margin: 10px;
}

.linkedIn {
  fill: #f8f8f2;
}

.linkedIn:hover {
  fill: #0073b1;
}

.github {
  fill: #f8f8f2;
}

.github:hover {
  fill: lightgray;
}

.socal-link {
  text-decoration: none !important;
}
