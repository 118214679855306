body {
  background-color: #282a36;
  margin: 0;
  overflow: auto;
  font-family: Monaco, Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.regular-text {
  color: #f8f8f2;
}

p,
li,
i,
div {
  color: #f8f8f2;
}

h4 {
  margin-top: 0;
  margin-bottom: 5;
}
